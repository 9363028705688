.book__header{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.book__search-wrapper{
   // padding-right: 2rem;
    position: relative;
}
.book__spinner{
    top: 4px;
    left: -20px;
}