@import '../../scss/mixins';

.names-card {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    background: #FCFCFC;
    padding: 1.5rem 4rem;
    color: #000;
    text-decoration: none;
    position: relative;
}

.names-card__link {
 position: absolute;
 width: 90%;
 height: 100%;
 left: 0;
}

.names-card__info-wrapper {
    display: flex;
    align-items: center;
}

.names-card__number {
    font-size: 2.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1.44px;
    margin-right: 5rem;
}

.names-card__title {
    font-size: 2.2rem;
    font-weight: 500;
    letter-spacing: -1.2px;
    line-height: normal;

}

.names-card__text {
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: -0.72px;
    line-height: normal;
    opacity: 0.6;
    overflow:hidden; 
    white-space:nowrap; 
    text-overflow:ellipsis; 
    width:150px; 
}

.names-card__button {
    display: block;
    padding: 0;
    width: 3rem;
    height: 3rem;
    background-color: transparent;
    @include hover-effect;
}

.names-card__heart {
    width: 3rem;
    height: 3rem;
    z-index: 2;

}