:root {
    --background: lightyellow;
    --navbar-width: 25.6rem;
    --navbar-width-min: 8rem;
    --navbar-dark-primary: #009959;
    --navbar-dark-secondary: rgba(3, 107, 64, 0.702);
    --navbar-light-primary: #f5f6fa;
    --navbar-light-secondary: #fff;
}

@mixin item {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 1.6rem;
    padding: 1rem;
    text-align: left;
    cursor: pointer;
    padding-left: 0.8rem;
    border-radius: 1.6rem 0 0 1.6rem;
    background-attachment: fixed;
    transition: top 0.2s;

    &::before,
    &::after {
        opacity: 0;
        content: "";
        position: absolute;
        right: 0;
        bottom: 100%;
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 50%;
        box-shadow: 1.6rem 1.6rem var(--background);
    }

    &::after {
        top: 100%;
        box-shadow: 1.6rem -1.6rem var(--background);
    }
}

.sidebar {
    height: calc(100vh - 10rem);
    max-height: 1200px;
    position: relative;
    padding-left: 1.6rem;
    margin-top: 1rem;
    z-index: 2;
}

.sidebar__list {
    padding: 1.5rem;
}

.nav-item-link {
    @include item()
}

.nav-item-image {
    filter: brightness(0) invert(1);
    padding-right: 1.2rem;
    width: 4rem;
    height: 4rem
        
}

.nav-item-link--active {
    @include item();
    background: var(--background);
    &::before,
    &::after {
        opacity: 1;
        pointer-events: none;
    }

    .nav-item-image {
        filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
    }

    .nav-span {
        color: #32e007;
    }
}

.nav-list {
    height: 100%;
    width: var(--navbar-width);
    transition: width 0.2s;
}

.nav-span {
    font-size: 1.8rem;
    font-weight: 600;
    flex-shrink: 0;
    //width: 150px;
    text-align: center;
}

#nav-toggle:checked~.nav-list {
    width: var(--navbar-width-min);

    .nav-item {
        .nav-item-link,
        .nav-item-link--active {
            padding-left: 0.5rem;
            .nav-span {
                opacity: 0;
                position: absolute;
            }
        }
    }
}

#nav-toggle:checked~#nav-header {
    width: calc(var(--navbar-width-min) - 1.6rem);
}

#nav-toggle:checked~#nav-content,
#nav-toggle:checked~#nav-footer {
    width: var(--navbar-width-min);
}

#nav-toggle:checked~#nav-header .nav-title {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.1s;
}

#nav-toggle:checked~#nav-header label[for=nav-toggle] {
    left: calc(50% - 0.8rem);
    transform: translate(-50%);
}

#nav-toggle:checked~#nav-header #nav-toggle-burger {
    background: var(--navbar-light-primary);
}

#nav-toggle:checked~#nav-header #nav-toggle-burger:before,
#nav-toggle:checked~#nav-header #nav-toggle-burger::after {
    width: 16px;
    background: var(--navbar-light-secondary);
    transform: translate(0, 0) rotate(0deg);
}

#nav-toggle:checked~#nav-footer #nav-footer-avatar {
    margin-left: 0;
    left: 50%;
    transform: translate(-50%);
}

#nav-toggle:checked~#nav-footer #nav-footer-titlebox,
#nav-toggle:checked~#nav-footer label[for=nav-footer-toggle] {
    opacity: 0;
    transition: opacity 0.1s;
    pointer-events: none;
}
// #nav-toggle:checked~#nav-footer  #nav-footer-heading{
  
//         #nav-footer-avatar{
//             left: 0;
//             display: block;
//             width: 30px;
//             height: 30px;
//         }
    
// }
#nav-bar {
    //position: fixed;
    background: var(--navbar-dark-primary);
    border-radius: 1.5rem;
    display: flex;
    flex-direction: column;
    color: var(--navbar-light-primary);
    overflow: hidden;
    user-select: none;
    height: 100%;

}

#nav-bar a {
    color: inherit;
    text-decoration: inherit;
}

#nav-bar input[type=checkbox] {
    display: none;
}

#nav-header {
    position: relative;
    width: var(--navbar-width);
    left: 1.6rem;
    width: calc(var(--navbar-width) - 1.6rem);
    padding: 1.5rem 0px;
    background: var(--navbar-dark-primary);
    border-radius: 1.6rem;
    display: flex;
    align-items: center;
    transition: width 0.2s;
}

.nav-title {
    font-size: 2rem;
    transition: opacity 1s;
}

label[for=nav-toggle] {
    position: absolute;
    right: 0;
    width: 3rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

#nav-toggle-burger {
    position: relative;
    width: 16px;
    height: 2px;
    background: var(--navbar-dark-primary);
    border-radius: 99px;
    transition: background 0.2s;
}

#nav-toggle-burger:before,
#nav-toggle-burger:after {
    content: "";
    position: absolute;
    top: -6px;
    width: 10px;
    height: 2px;
    background: var(--navbar-light-primary);
    border-radius: 99px;
    transform: translate(2px, 8px) rotate(30deg);
    transition: 0.2s;
}

#nav-toggle-burger:after {
    top: 6px;
    transform: translate(2px, -8px) rotate(-30deg);
}

.nav-list::-webkit-scrollbar {
    width: 0.8rem;
    height: 0.8rem;
}

.nav-list::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background-color: #D62929;
}

.nav-list::-webkit-scrollbar-button {
    height: 1.6rem;
}

#nav-footer {
    width: var(--navbar-width);
    height: 5.4rem;
    background: var(--navbar-dark-secondary);
    border-radius: 1.6rem;
    z-index: 2;
    transition: width 0.2s, height 0.2s;
}

#nav-footer-heading {
    position: relative;
    width: 100%;
    height: 5.4rem;
    display: flex;
    align-items: center;
}

#nav-footer-avatar {
    position: relative;
    margin: 1.1rem 0 1.1rem 1.6rem;
    left: 0;
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    transform: translate(0);
    transition: 0.2s;
}

#nav-footer-avatar img {
    height: 100%;
    border-radius: 50%;
}

#nav-footer-titlebox {
    position: relative;
    margin-left: 1.6rem;
    display: flex;
    flex-direction: column;
    transition: opacity 1s;

}
#nav-footer-title{
    display: block;
   // background: linear-gradient(to right, #f32170, #ff6b08,#cf23cf, #eedd44);
   background: linear-gradient(to right, #ef3b36, #ffffff)
;    -webkit-text-fill-color: transparent;
    background-clip: text;
}

#nav-footer-subtitle {
    color: var(--navbar-light-secondary);
    font-size: 0.6rem;
}
@media only screen and (max-width:950px){
   .sidebar{
    margin-top: 2.5rem;
    height: calc(100vh - 11.2rem);
   }
}
@media only screen and (max-width:650px){
    .sidebar{
        position: fixed;
        width: 96%;
        max-width: 450px;
        left: calc(50% - 225px);
        bottom: 2rem;
        height: 6rem;
        padding: 0;
        margin: 0;
        margin-top: 1rem;
        box-sizing: border-box;
        box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.6);
        border-radius: 1.6rem;
        z-index: 90;
        opacity: 1;
    }
    #nav-header{
        display: none;
    }
    #nav-footer{
        display: none;
    }
    .nav-list{
        width: 100%;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 2.5rem;
        padding-bottom: 0.5rem;
        box-sizing: border-box;
    }
    .nav-item-image{
        padding: 0;
        width: 3.5rem;
        height: 3.5rem;
    }
    .nav-item-link{
        margin: 0;
        margin: 5px  0;
        padding: 0.5rem;
    }
    .nav-item-link--active{
        position: relative;
         margin: 0;
        margin: 5px  0;
        padding: 0.5rem;
        box-shadow: 0px 0px 2px 2px var(--background);
        border-radius:1.6rem;
        transition: 0.6s;
        &::before {
           display: none;
            right: 100%;
            top: 15px;
            box-shadow: 1rem 1rem var(--background);
            //
        }
        &::after{
            display: none;
            box-shadow: -1rem 1rem var(--background);
            left: 100%;
            top: 15px ;
            //transform: rotateY(45deg);
        }
    }
    .nav-span{
        display: none;
    }
}
@media only screen and (max-width:460px){
.sidebar{
    left: calc(50% - 48%);
    bottom: 2rem;
}
}