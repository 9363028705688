

.names__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.names__title {
    font-size: 4rem;
    padding-top: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -2px;
}

.names__page-button {
    border-radius: 2.5rem;
    background-color: #F4F4F4;
    padding: 1rem 1.5rem 1rem 4.9rem;
    font-size: 1.8rem;
    border: 1px solid #F4F4F4;
    position: relative;
    background-image: url(../../assets/img/liked.svg) ;
    background-repeat: no-repeat;
    background-position: 1rem;
    &:disabled{
        opacity: 0.5;
        color: black;
        cursor:no-drop;
    }
}

.names__page-button--active {
    color: #009959;
    border: 1px solid #009959;
    background-color: #fff;
    background-image: url(../../assets/img/like-green.svg);
   
}
.names__body{
    margin-top: 4rem;
}
@media only screen and (max-width:870px){
    .names__page-button{
        padding: 0;
        width: 6rem;
        height: 5rem;
        background-position: center center;
        span{
            display: none;
           
        }
        
    }
}

