.surah-card{
    max-width: 330px;
    min-width: 280px;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    //align-items: center;
    border-radius: 10px;
    background: #FCFCFC;
    padding: 1.5rem;
    color: #000;
    text-decoration: none;
    position: relative;  
    transition: 0.3s;
    &:hover{
        background: #009959;
        color: #fff;
        transition: 0.3s;
        .surah-card__number{
            color: #fff;
            background-color: #009959;
            //transition: 0.3s;
            .surah-card__svg{
                color: #fff;
            }
        }
    }
}
.surah-card__leftbox{
    display: flex;
    align-items: center;

}
.surah-card__number{
        position: relative;
        margin-right: 1.1rem;
        min-height: 35px;
        aspect-ratio: 1 / 1;
        font-family: "Roboto Serif";
        .surah-card__svg {
           display: block;
           height: 100%;
           width: 100%;
          color: #009959;
        }
     
        span {
           position: absolute;
           top: 50%;
           left: 50%;
           transform: translateY(-50%) translateX(-50%);
           font-size: 1.6rem;
           
        }
     
 
}

.surah-card__name{
    font-family: 'Roboto Slab';
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: -1.2px;
    line-height: normal;
}
.surah-card__link{
    position: absolute;
    width: 100%;
    left: 0;
    height: 100%;
}
.arabic-name{
    font-family: 'Noto Naskh Arabic';
    text-align: right;
    text-decoration: none;
    font-size: 2.5rem;
}
@media only screen and (max-width:870px){
    .surah-card{
        max-width: 100%;
        width: 100%;
    }
    .surah-card__number{
        margin-right: 2rem;
    }
}