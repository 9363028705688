.timings__list{
   margin: 6rem 0;
  // margin-bottom: 16rem;
}
.timings__tablet{
   position: absolute;
   bottom: 0;
   width:calc(100% - 0.5rem);
   padding: 0;
   //overflow: hidden;
   height: auto;
   //left: calc(50% - 0.5rem);
}
 
 