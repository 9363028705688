.calendar-card {
    width:370px;
    border-radius: 1rem;
    border: 1px solid #d0d0d0;
    margin-bottom: 1.5rem;
    background-color: #F5F5F5;
}
.calendar-card__head {
    display: flex;
    padding: 0.7rem 2rem;
    border-bottom: 1px solid #d0d0d0;
    align-items: center;
}
.calendar-card__list {
    display: flex;
    justify-content: space-between;
   // padding: 1rem 2rem;
    
}
.calendar-card__item {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: -0.04em;
    text-align: center;
    padding: 0.6rem 1.5rem 1rem 1.5rem;
    flex-grow: 1;
    border-right: 1px solid #d0d0d0;
    &:last-child{
        border-right: none;
    }

}
.calendar-card__day-name {
    font-size: 1.7rem;
    font-weight: 500;
    letter-spacing: -0.04em;
    text-align: center;
    margin: 0 auto;

}
.calendar-card__day-number {
    
    background: #F5F5F5;
  
}
.calendar-card__number{
    text-align: center;
    position: absolute;
    width: 3rem;
    height: 3rem;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 3rem;
    border-radius: 50%;
}
.calendar-card__active{
    width: 370px;
    border-radius: 1rem;
    border: 1px solid #d1d1d1;
    margin-bottom: 1.5rem;
    box-shadow: 0 0 30px #e6e5e5;
    .calendar-card__head{
        border-bottom: 1px solid #d1d1d1;
    }
    .calendar-card__item{
        border-right: 1px solid #d1d1d1;
        &:last-child{
            border-right: none;
        }
    }
    .calendar-card__number{
        background-color: #009959;
        color: #F4F4F4;
    }
    .calendar-card__day-name{
        color: #009959;
    }
}
@media only screen and (max-width:870px){
  .calendar-card, .calendar-card__active{
    width: 100%;
  }
  
}