.page-control {
    width: 9.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.page-control__button{
    display: block;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    background: url(../../img//control.svg) no-repeat center center;
    background-size: contain;
}
.page-control__button--next{
    transform: rotate(180deg); 
}
@media only screen and (max-width:650px){
    .page-control{
        width: 7rem;
    }
    .page-control__button{
        width: 3rem;
        height: 3rem;
    }
}