@mixin hover-effect {
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-property: transform;
    transition-property: transform;
  
    &:active {
      transform: scale(0.5);
      -webkit-transform: scale(0.5);
    }
  }