.option-flag{
    width: 20px ;
    height: 12px;
}
.option-wrapper{
    display: flex;
    align-items: center;
}
.option-item{
    margin-left: 5px;
}
.form__input-wrapper{
    position: relative;
}
.form__input-spinner{
    top: 9px;
    right: -18px;
}
.form{
    padding: 20px 25px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 30px 50px rgba(149, 144, 144, 0.618), 0 45px 26px rgba(172, 162, 162, 0.624);
}
.form__button{
    background-color: #009959;
    color: #fff;
    font-family: 'Jost';
    &:hover{
        background: #fff;
        color: #009959 !important;
        border: 1px solid  #009959 !important;
    }
}
@media only screen and (max-width:750px){
    .form__input-spinner{
        right: -20px;
    }
}
