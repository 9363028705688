html{
  font-size: 62.5%;
}
body {
  margin: 0 auto;
  padding: 0;
  font-family: "Jost", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.5;
  max-width: 1920px;
  background-color: lightyellow;
  background-image: url(../img//home-bg.png) ;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
  background-position-y: 80px;
  background-attachment: fixed;
  border-radius: 19px;
  position: relative;
 &::-webkit-scrollbar {
    width: 8px;
    max-height: 8px;
    background-color: transparent;
}
&::-webkit-scrollbar-thumb {
  border-radius: 99px;
  background-color: transparent;

}
 
}

input {
  border: none;
}

p,
h1,
h2,
h3,
h4 {
  margin: 0;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

.app__main {
  display: flex;
  margin-top: 7rem;
}

.app__body{
  flex-grow: 1;
//max-width: 1420px;
position: relative;
width: auto;
box-sizing: border-box;
overflow: hidden;
//height: calc(100vh - 90px);
}

.container {
  position: relative;
  border-radius: 10px;
  background: #FFF;
  margin: 1.5rem;
  height: calc(100vh - 120px);
  overflow-y: scroll;
  max-height: 1200px;
  padding: 1.5rem 3rem;
  padding-bottom: 0;
  width: auto;
  &::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    
}
&::-webkit-scrollbar-thumb {
  border-radius: 90px;
  padding-right: 2px;
  background-color: #a9a7a7b3;
}

}
.ant-input,.ant-select-selector, .ant-select-selection-search-input, .ant-select-selection-search{
  //border-color: #009959;
  &:focus,&:hover{
    border-color: #009959;
    outline: #009959;
  }
}
button{
  background: transparent;
  font-family: "Jost";
  font-size: 1.6rem;
  padding: 0;
}
.input {
  background-color: #d9d9d933;
  font-family: "Jost";
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: -0.04em;
  color: rgba(2, 2, 2, 1);
  width: 35%;
  border-radius: 5px;
  padding: 10px;

  &::placeholder {
    color: rgba(0, 0, 0, .3);
    opacity: 0.7;
  }

  &:focus {
    outline: none;
  }
}
.ant-select-selection-item{
  background-color: transparent !important;
  margin: 0 !important;
  padding: 0 !important;
}
.ant-select-selection-item-remove{
  opacity: 0;
  display: none !important;
}

@media only screen and (max-width:950px){
  html{
    font-size: 50%;
  }
  .app__body{
    margin-top: 1.5rem;
 
  }
  .container{
    height: calc(100vh - 105px);
    padding: 1.5rem;
    padding-bottom: 0;
  }
  
}
@media only screen and (max-width:650px){
  body{
    background-image: none;
  }
.app__main{
  margin-top: 0.5rem;
 display: block;
}
.app__body{
  margin-top: 0;
  flex-grow: 0;
  max-width: 100%;
  position: relative;
}
.tablet-timings{
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.container{
  margin: 0.5rem;
  bottom: 1rem;
  padding: 1rem;
  padding-top: 2rem;
  height: 100%;
  position: relative;
  margin-top: 0;
  height: 98vh;
}
.overflow-hidden-container{
  overflow: hidden;
  padding: 0;
 height: 100vh;
  //height: calc(100vh - 13rem);
}
}


button {
  border: none;
  cursor: pointer;
}