.header{
    background-color: #fff;
    width: 100%;
   // height: 60px;
    padding: 10px 0;
    max-width: 1920px;
    position: fixed;
    z-index: 99;
    display: flex;
    top: 0;
    align-items: center;
    backdrop-filter: blur(5px);
}
.header-logo-link{
    margin-left: 90px;
   
}
.header-locality{
    font-weight: 500;
    font-size: 3.2rem;
    line-height: 48px;
    letter-spacing: -0.04em;
    margin: 0 auto;
    position: relative;
    &::after{
        position: absolute;
        width: 2.2rem;
        height: 2.2rem;
        content: '';
        left: -3rem;
        background: url(../../../assets/img/pin-map.svg) no-repeat center center;
        background-size: contain;
        top: 12px;
    }
   
}
@media only screen and (max-width:1100px){
.header-logo-link{
    margin-left: 30px;
}
}
@media only screen and (max-width:650px){
    .header{
        display: none;
    }
}