
.swiper-slide{
    height: 310px;
    max-width: 280px;
    min-width: 265px;
    border-radius: 30px;
    margin: 20px 0;
  margin-bottom: 75px;
    cursor: pointer;
}

.swiper-wrapper{
    display: flex;
    align-items: flex-end;
    height: auto;
    width: auto;
   padding-bottom: 2rem;
}

.swiper-pagination-bullet{
    width: 10px;
    height: 10px;
    opacity: 1;
 
    background-color: black;
}