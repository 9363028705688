.oyat-card{
    border-radius: 2rem;
    margin-bottom: 3rem;
    // padding: 2rem 1.5rem;
    // padding-top: 0;
    background: #83ebc080;
}
.oyat-card__wrapper{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: 1rem 2rem;
    border-radius: 2rem;
    background: #83ebc080;
    align-items: center;
    //color: #fff;
   
}
.oyat-card__number{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 35px;
    font-size: 1.6rem;
    font-weight: 500;
    border-radius: 50%;
    background-color: #009959;
   color: #fff;
   font-family: 'Roboto Slab';
}

.oyat-card__text{
    font-family: 'Roboto Slab';
    font-size: 1.6rem;
    font-weight: 400;
    padding: 1.5rem;
}
.arabic-text{
    font-family: 'Noto Naskh Arabic';
    text-align: right;
    text-decoration: none;
    font-size: 4rem;
    
}
.oyat-list__list{
    margin-top: 3rem;
}
.oyat-card__audio-button{
    background: transparent;
    justify-self: end;
    &:focus{
       border: none;
       outline: none;
    }
}