.surah__header{
display: flex;
justify-content: space-between;
align-items: flex-start;
}
.surah__title{
    text-align: center;
    width: 100%;
}
.surah__box{
    width: 94px;
}
.surah__body{
    margin: 3.5rem 0;
}