.map-page__header{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    
}
.map-page__body{
    margin: 3.5rem 0;
    height: 80%;
}
.map-page__iframe{
    width: 100%;
    height: 110%;
    border-radius: 1rem;
    border: none;
}
.map-page__country{
    font-family: "Poppins";
    font-weight: 500;
    font-size: 2.8rem;
    //padding-left: 3rem;
    position: relative;
    text-align: 
    center;
    
}
.map-page__state{
    font-family: 'Roboto Serif';
    text-align: center;
    color: gray;
    font-size: 1.8rem;
}
@media only screen and (max-width:650px){
    .map-page__search{
        background-color: rgb(0, 0, 0,0.75);
    }
    .map-page__iframe{
        height: 100%;
    }
}