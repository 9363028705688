.search-input__input{
    max-width: 150px;
}
@media only screen and (max-width:870px){
  .search-input__input{
    max-width: 100px;
    font-size: 1.3rem;
    padding: .4rem;
    
  }
}
@media only screen and (max-width:450px){
   .search-input__input{
    max-width: 80px;
    font-size: 1.2rem;
   }
}