@keyframes audio-open{
    0%{
        margin-top: -55px;
        
    }
    100%{
        margin-top: 12px;
    }
}
@keyframes audio-close{
    0%{
         margin-top: 12px;
    }
    100%{
        margin-top: -55px;
       
    }
}

.audio{
    background: rgba(3, 107, 64, 0.902);
    color: #fff;
    max-width: 100%;
    width: auto;
    margin:10px 17px;
    border-radius: 3rem;
    padding: 1rem 2rem;
    z-index: 9;
    margin-bottom: 0;
    animation: audio-open ease 500ms;
}
.audio--closed{
    background: #009959;
    color: #fff;
    max-width: 100%;
    width: auto;
    margin: 10px 17px;
    margin-top: -55px;
    border-radius: 3rem;
    padding: 1rem 2rem;
    z-index: 9;
    animation: audio-close ease 400ms;
    margin-bottom: 0;
}
.audio__inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    //width: 720px;
}
.audio__info{
    display: flex;
    align-items: center;
}
.audio__button{
    margin-right: 2rem;
}
.audio__ayahname{
    margin-right: 2rem;
    text-decoration: none;
    color: #fff;
}
.audio__span{
    font-size: 1.6rem;
    font-weight: 500;
}

@media only screen and (max-width:650px){
    @keyframes audio-open {
        0%{
            bottom: -100px;
        }
        100%{
            bottom: 10rem;
        }
    }
    @keyframes audio-close {
        0%{
            bottom: 10rem;
        }
        100%{
            bottom: -100px;
        }
    }
    .audio , .audio--closed{
        position: fixed;
        max-width: 90%;
        width: 90%;
        padding: 1rem 0;
        margin: 0;
        left: calc(50% - 45%);
        bottom: 10rem;
        z-index: 99;
    }
    .audio--closed{
        bottom: -100px;
    }
    .audio__button{
        margin-left: 3rem;
    }
    .audio__close-btn{
        margin-right: 3rem;
    }
}