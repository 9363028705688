/* jost-regular - latin */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    src: local(''),
         url('../../assets/fonts/jost-v14-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../assets/fonts/jost-v14-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* jost-500 - latin */
  @font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    src: local(''),
         url('../../assets/fonts/jost-v14-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../assets/fonts/jost-v14-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* jost-700 - latin */
  @font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 700;
    src: local(''),
         url('../../assets/fonts/jost-v14-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../assets/fonts/jost-v14-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* jost-900 - latin */
  @font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 900;
    src: local(''),
         url('../../assets/fonts/jost-v14-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../assets/fonts/jost-v14-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* jost-italic - latin */
  @font-face {
    font-family: 'Jost';
    font-style: italic;
    font-weight: 400;
    src: local(''),
         url('../../assets/fonts/jost-v14-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../assets/fonts/jost-v14-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* jost-300 - latin_cyrillic */
@font-face {
     font-family: 'Jost';
     font-style: normal;
     font-weight: 300;
     src: local(''),
          url('../../assets/fonts/jost-v14-latin_cyrillic-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
          url('../../assets/fonts/jost-v14-latin_cyrillic-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* jost-regular - latin_cyrillic */
   @font-face {
     font-family: 'Jost';
     font-style: normal;
     font-weight: 400;
     src: local(''),
          url('../../assets/fonts/jost-v14-latin_cyrillic-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
          url('../../assets/fonts/jost-v14-latin_cyrillic-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* jost-500 - latin_cyrillic */
   @font-face {
     font-family: 'Jost';
     font-style: normal;
     font-weight: 500;
     src: local(''),
          url('../../assets/fonts/jost-v14-latin_cyrillic-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
          url('../../assets/fonts/jost-v14-latin_cyrillic-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* jost-600 - latin_cyrillic */
   @font-face {
     font-family: 'Jost';
     font-style: normal;
     font-weight: 600;
     src: local(''),
          url('../../assets/fonts/jost-v14-latin_cyrillic-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
          url('../../assets/fonts/jost-v14-latin_cyrillic-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* jost-700 - latin_cyrillic */
   @font-face {
     font-family: 'Jost';
     font-style: normal;
     font-weight: 700;
     src: local(''),
          url('../../assets/fonts/jost-v14-latin_cyrillic-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
          url('../../assets/fonts/jost-v14-latin_cyrillic-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* roboto-slab-regular - latin_cyrillic-ext_cyrillic */
@font-face {
     font-family: 'Roboto Slab';
     font-style: normal;
     font-weight: 400;
     src: local(''),
          url('../../assets/fonts/roboto-slab-v24-latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
          url('../../assets/fonts/roboto-slab-v24-latin_cyrillic-ext_cyrillic-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* roboto-slab-500 - latin_cyrillic-ext_cyrillic */
   @font-face {
     font-family: 'Roboto Slab';
     font-style: normal;
     font-weight: 500;
     src: local(''),
          url('../../assets/fonts/roboto-slab-v24-latin_cyrillic-ext_cyrillic-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
          url('../../assets/fonts/roboto-slab-v24-latin_cyrillic-ext_cyrillic-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* roboto-slab-600 - latin_cyrillic-ext_cyrillic */
   @font-face {
     font-family: 'Roboto Slab';
     font-style: normal;
     font-weight: 600;
     src: local(''),
          url('../../assets/fonts/roboto-slab-v24-latin_cyrillic-ext_cyrillic-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
          url('../../assets/fonts/roboto-slab-v24-latin_cyrillic-ext_cyrillic-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* roboto-slab-700 - latin_cyrillic-ext_cyrillic */
   @font-face {
     font-family: 'Roboto Slab';
     font-style: normal;
     font-weight: 700;
     src: local(''),
          url('../../assets/fonts/roboto-slab-v24-latin_cyrillic-ext_cyrillic-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
          url('../../assets/fonts/roboto-slab-v24-latin_cyrillic-ext_cyrillic-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* roboto-serif-regular - latin_cyrillic-ext */
@font-face {
     font-family: 'Roboto Serif';
     font-style: normal;
     font-weight: 400;
     src: local(''),
          url('../../assets/fonts/roboto-serif-v8-latin_cyrillic-ext-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
          url('../../assets/fonts/roboto-serif-v8-latin_cyrillic-ext-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* roboto-serif-italic - latin_cyrillic-ext */
   @font-face {
     font-family: 'Roboto Serif';
     font-style: italic;
     font-weight: 400;
     src: local(''),
          url('../../assets/fonts/roboto-serif-v8-latin_cyrillic-ext-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
          url('../../assets/fonts/roboto-serif-v8-latin_cyrillic-ext-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* roboto-serif-500italic - latin_cyrillic-ext */
   @font-face {
     font-family: 'Roboto Serif';
     font-style: italic;
     font-weight: 500;
     src: local(''),
          url('../../assets/fonts/roboto-serif-v8-latin_cyrillic-ext-500italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
          url('../../assets/fonts/roboto-serif-v8-latin_cyrillic-ext-500italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* roboto-serif-700italic - latin_cyrillic-ext */
   @font-face {
     font-family: 'Roboto Serif';
     font-style: italic;
     font-weight: 700;
     src: local(''),
          url('../../assets/fonts/roboto-serif-v8-latin_cyrillic-ext-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
          url('../../assets/fonts/roboto-serif-v8-latin_cyrillic-ext-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* noto-naskh-arabic-regular - latin_arabic */
@font-face {
     font-family: 'Noto Naskh Arabic';
     font-style: normal;
     font-weight: 400;
     src: local(''),
          url('../../assets/fonts/noto-naskh-arabic-v25-latin_arabic-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
          url('../../assets/fonts/noto-naskh-arabic-v25-latin_arabic-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* noto-naskh-arabic-500 - latin_arabic */
   @font-face {
     font-family: 'Noto Naskh Arabic';
     font-style: normal;
     font-weight: 500;
     src: local(''),
          url('../../assets/fonts/noto-naskh-arabic-v25-latin_arabic-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
          url('../../assets/fonts/noto-naskh-arabic-v25-latin_arabic-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* poppins-regular - latin */
@font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Poppins';
     font-style: normal;
     font-weight: 400;
     src: url('../../assets/fonts/poppins-v20-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
          url('../../assets/fonts/poppins-v20-latin-regular.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
   }
   /* poppins-italic - latin */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Poppins';
     font-style: italic;
     font-weight: 400;
     src: url('../../assets/fonts/poppins-v20-latin-italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
          url('../../assets/fonts/poppins-v20-latin-italic.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
   }
   /* poppins-500 - latin */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Poppins';
     font-style: normal;
     font-weight: 500;
     src: url('../../assets/fonts/poppins-v20-latin-500.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
          url('../../assets/fonts/poppins-v20-latin-500.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
   }
   /* poppins-600 - latin */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Poppins';
     font-style: normal;
     font-weight: 600;
     src: url('../../assets/fonts/poppins-v20-latin-600.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
          url('../../assets/fonts/poppins-v20-latin-600.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
   }
   /* poppins-700 - latin */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Poppins';
     font-style: normal;
     font-weight: 700;
     src: url('../../assets/fonts/poppins-v20-latin-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
          url('../../assets/fonts/poppins-v20-latin-700.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
   }
   /* poppins-800 - latin */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Poppins';
     font-style: normal;
     font-weight: 800;
     src: url('../../assets/fonts/poppins-v20-latin-800.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
          url('../../assets/fonts/poppins-v20-latin-800.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
   }