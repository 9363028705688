@keyframes search-active {
    0%{
        top: -500px;
    }
    100%{
        top: 10px;
    }
}
@keyframes search-close {
    0%{
        top: 10px;
    }
    100%{
        top: -300px;
    }
}
@mixin button {
    width: 4rem;
    height: 4rem;
    display: block;
    background-color: #009959;
    border-radius: 50%;
    background-repeat: no-repeat;
        background-size: 80%;
        background-position: center center;
        @media only screen and (max-width:650px){
            margin: 0;
        }
    
}
.search{
    //margin-left: auto;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    position: relative;
}

.search__button {
    @include button();
        background-image: url(../../img//search-button.svg);
       // background-size: contain;
        
}
.search__button--close{
    @include button();
   background-image: url(../../img//x.svg);
      
    
}
.search__wrapper{
    position: absolute;
    top: -500px;
    right: 50px;
    animation-name: search-close;
    animation-duration: 250ms;
}
.search__wrapper--active{
    position: absolute;
    top:10px;
    z-index: 29;
    right: 50px;
    border-radius: 20px;
   
    
    animation-name: search-active;
    animation-duration: 200ms;
}
@media only screen and (max-width:650px) {
    @mixin button{
        background-color: #ffffff33;
        margin: 0;
    }
    .search__button{ 
        background-image: url(../../img/search-tab.svg);
    }
    
}