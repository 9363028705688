.title{
    font-size: 3.6rem;
    padding-top: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -2px;
    //text-align: center;
}
@media only screen and (max-width:480px) {
    .title{
        font-size: 2.6rem;
    }
    
}