
.timings__item {
    width: 100%;
    height: 100%;
    border-radius: 30px;
    color: #FFFFFF;
    text-align: center;
    padding-top: 20px;
    position: relative;
    margin-right: 30px;
    &:last-child{
        margin-right: 0;
    }

    &::after {
        position: absolute;
        width: 100%;
        height: 100%;
        content: '';
        background-size: contain;
        top: 0;
        left: 0;
        border-radius: 30px;
    }

    &::before {
        position: absolute;
        width: 130px;
        height: 130px;
        content: '';
    }
}
.timings__item-name{
font-size: 30px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -1.6px;
margin-top: 10px;
}
.timings__item-time{
    font-size: 25px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -1.2px;
z-index: 99;
}